import $ from 'jquery'

// TODO: implement text
const markLoading = function(el, text, cls, useDummy) {
  if (text == null) { text = ''; }
  if (cls == null) { cls = 'simple'; }
  if (useDummy == null) { useDummy = true; }
  const $el = $(el);

  $el.addClass('loading');

  if ($el.is(':empty')) { $el.append($('<div class="dummy-content"></div>')); }

  if ($el.children('.mz-preloader').length === 0) {
    var title_text = title_text ? `<div class='title'>${text}</div>` : "";
    const template = `\
<div class="mz-preloader ${cls}">
  <div class="block">
    <div class="area">
      ${title_text}
      <div class="wrapper">
        <svg class="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" cx="33" cy="33" r="30" stroke-linecap="round"/>
        </svg>
      </div>
    </div>
  </div>
</div>\
`;
    return $el.append($(template));
  }
};

const removeLoading = el => $(el)
  .removeClass('loading')
  .children('.mz-preloader,.dummy-content').remove();

export { markLoading, removeLoading }
