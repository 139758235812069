import { Controller } from "stimulus"
import { fire } from "@rails/ujs"

export default class extends Controller {
  pick(event) {
    event.preventDefault();
    const eventToFire = this.data.has('event') ? this.data.get('event') : 'picker:picked';
    return fire(event.currentTarget, eventToFire, { id: event.currentTarget.getAttribute('data-picker-id') });
  }
}
