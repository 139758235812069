import {Controller} from "stimulus"
import consumer from "../channels/consumer"
import CableReady from "cable_ready"

export default class extends Controller {
  connect() {
    console.log('connected')
    console.log(this.element)
    this.subscription = consumer.subscriptions.create(
      {
        channel: this.data.get('channel'),
        object: this.data.get('object')
      }, {
        received(data) {
          if (data.cableReady) CableReady.perform(data.operations)
        }
      }
    )
  }

  disconnect() {
    this.subscription.unsubscribe()
    console.log('disconnected')
    console.log(this.element)
  }
}
