import { Controller } from "stimulus"
import { activateLink, activateReload } from "mz_ui/utils/activate_link"

export default class extends Controller {
  bubbleLoad(event) {
    let url = this.data.has('url') ? this.data.get('url') : this.element.getAttribute('href')
    activateLink(url, this.element);
    if (event != null) { return event.preventDefault(); }
  }

  bubbleReload(event) {
    activateReload(this.element);
    if (event != null) { return event.preventDefault(); }
  }
}
