import Rails from "@rails/ujs"

let delegate = Rails.delegate, getData = Rails.getData, $ = Rails.$, fire = Rails.fire

let disableElement = function(e) {
  var element = e instanceof Event ? e.target : e;
  fire(element, 'ujs:disable')
}

let enableElement = function(e) {
  var element = e instanceof Event ? e.target : e;
  fire(element, 'ujs:enable')
}

let start = function() {
  window.addEventListener('pageshow', function() {
    $(Rails.formEnableSelector).forEach(function(el) {
      if (getData(el, 'ujs:disabled')) {
        return enableElement(el);
      }
    });
    return $(Rails.linkDisableSelector).forEach(function(el) {
      if (getData(el, 'ujs:disabled')) {
        return enableElement(el);
      }
    });
  });
  delegate(document, Rails.linkDisableSelector, 'ajax:complete', enableElement);
  delegate(document, Rails.linkDisableSelector, 'ajax:stopped', enableElement);
  delegate(document, Rails.buttonDisableSelector, 'ajax:complete', enableElement);
  delegate(document, Rails.buttonDisableSelector, 'ajax:stopped', enableElement);
  delegate(document, Rails.linkClickSelector, 'click', disableElement);
  delegate(document, Rails.buttonClickSelector, 'click', disableElement);
  delegate(document, Rails.formSubmitSelector, 'submit', function(e) {
    return setTimeout((function() {
      return disableElement(e);
    }), 13);
  });
  delegate(document, Rails.formSubmitSelector, 'ajax:send', disableElement);
  delegate(document, Rails.formSubmitSelector, 'ajax:complete', enableElement);
}

export { start }
