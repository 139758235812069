import { Controller } from "stimulus"
import $ from "jquery"
import "mz_ui/utils/table_fixer"

export default class extends Controller {
  static targets = [ 'table' ]
  connect() {
    let head = false
    let foot = false
    let left = 0
    let right = 0
    let zIndex = 1

    if (this.data.has('head')) { head = true }
    if (this.data.has('foot')) { foot = true }
    if (this.data.has('left')) { left = this.data.get('left') }
    if (this.data.has('right')) { left = this.data.get('right') }
    if (this.data.has('zIndex')) { zIndex = this.data.get('zIndex') }

    $(this.tableTarget).tableFixer({
      head,
      foot,
      left,
      right,
      'z-index': zIndex
    });
  }
}
