import { Controller } from "stimulus"
import Inputmask from "inputmask";

export default class extends Controller {
  connect() {
    Inputmask(this.data.get('mask')).mask(this.element);
  }

  disconnect() {
    if (this.element.inputmask) {
      this.element.inputmask.remove()
    }
  }
}
