import $ from "jquery"
import { matches } from "@rails/ujs"
import { toArray } from "mz_ui/utils/helpers"

let inputHasValue = function(input) {
  if (!input.name || input.disabled) { return false; }
  if (matches(input, 'fieldset[disabled] *')) { return false; }
  if (matches(input, 'select')) {
    return toArray(input.options).some(option => option.selected && ($(option).text() !== '') && $(option).val());
  } else if (input.checked || (['radio', 'checkbox', 'submit'].indexOf(input.type) === -1)) {
    return input.value;
  }
};

let serializeFilter = function(element, onlyPresent) {
  if (onlyPresent == null) { onlyPresent = false; }
  let inputs = [element];
  if (matches(element, 'form')) { inputs = toArray(element.elements); }
  const params = {};

  inputs.forEach(function(input) {
    if (!input.name || input.disabled) { return; }
    if (matches(input, 'fieldset[disabled] *')) { return; }
    if (matches(input, 'select')) {
      return toArray(input.options).forEach(function(option) {
        if (option.selected && ($(option).text() !== '') && $(option).val()) {
          if (!params[input.name]) { params[input.name] =  {
            name: input.name,
            label: $(`label[for='${input.getAttribute('id')}']`).text(),
            value: []
          }; }
          return params[input.name].value.push($(option).text());
        }
      });
    } else if (input.checked || (['radio', 'checkbox', 'submit'].indexOf(input.type) === -1)) {
      if (!onlyPresent || ((input.value != null) && (input.value !== ''))) {
        if (!params[input.name]) { params[input.name] =  {
          name: input.name,
          label: $(`label[for='${input.getAttribute('id')}']`).text(),
          value: []
        }; }

        let iv = input.value;

        if (input.type === 'checkbox') {
          iv = 'Да';
        } else if ((input.type === 'hidden') && matches(input, '.datetimerangepicker input, .datetimepicker input, .datepicker input')) {
          iv = $(input).siblings('input[type=text]').val();
          if (!iv) { iv = input.value; }
        }

        return params[input.name].value.push(iv);
      }
    }
  });

  return params;
};

let removeFilterFromQuery = function(query, filter) {
  const vars = query.split('&');
  let out = '';
  for (let v of Array.from(vars)) {
    const pair = v.split('=');
    if (decodeURIComponent(pair[0]) !== filter) {
      if (out.length > 0) { out += '&'; }
      out += v;
    }
  }
  return out;
};

export { inputHasValue, serializeFilter, removeFilterFromQuery }
