import $$ from 'mz_ui/utils/dom'
import { fire } from '@rails/ujs'

const activateLink = function(url, startElement, turbolinksReplace) {
  if (startElement == null) { startElement = $$('body'); }
  if (turbolinksReplace == null) { turbolinksReplace = false; }
  return fire(startElement, 'load', { url, turbolinksReplace });
};

const activateReload = function(startElement) {
  if (startElement == null) { startElement = $$('body'); }
  return fire(startElement, 'load');
};

export { activateLink, activateReload }
