import { Controller } from "stimulus"
import bsCustomFileInput from "mz_ui/components/mz_file_input"

export default class extends Controller {
  connect() {
    bsCustomFileInput.initFor(this.element);
  }

  disconnect() {
    bsCustomFileInput.destroyFor(this.element);
  }
}
