import jQuery from "jquery"
import "select2"

(function ($) {
  if (jQuery && jQuery.fn && jQuery.fn.select2 && jQuery.fn.select2.amd) var S2 = jQuery.fn.select2.amd;

  S2.define("MultiControlsDropdownAdapter", [
      'select2/dropdown',
      'select2/dropdown/attachBody',
      'select2/utils'
    ],
    function (Dropdown, AttachBody, Utils) {
      function SelectAll() {
      }

      SelectAll.prototype.render = function (decorated) {
        var $rendered = decorated.call(this);
        var self = this;

        var $selectAll = $('<a/>').addClass('btn btn-link').text('Выбрать все');
        var $selectNone = $('<a/>').addClass('btn btn-link').text('Очистить');

        var checkOptionsCount = function () {
          var count = $('.select2-results__option').length;
          $selectAll.prop('disabled', count > 25);
        };

        var $container = $('.select2-container');
        $container.bind('keyup click', checkOptionsCount);

        var $dropdown = $rendered.find('.select2-dropdown');

        $dropdown.prepend($selectNone);
        $dropdown.prepend($selectAll);

        $selectAll.on('click', function (e) {
          var $results = $rendered.find('.select2-results__option[aria-selected=false]');

          // // Get all results that aren't selected
          // $results.each(function () {
          //   var $result = $(this);
          //
          //   // Get the data object for it
          //   var data = Utils.GetData($result[0], 'data');//$result.data('data');
          //
          //   // Trigger the select event
          //   self.trigger('select', {
          //     data: data
          //   });
          // });

          var values = $results.map(function() {
            var $result = $(this);
            var data = Utils.GetData($result[0], 'data');
            return data.element.value;
          });

          self.$element.val(values);
          self.$element.trigger('change');

          self.trigger('close');
        });

        $selectNone.on('click', function (e) {
          // Trigger value changed with null value
          self.$element.val(null);
          self.$element.trigger('change');
          self.trigger('close');
        });

        return $rendered;
      };

      return Utils.Decorate(
        Utils.Decorate(
          Dropdown,
          AttachBody
        ),
        SelectAll
      )
    });

})(jQuery);
