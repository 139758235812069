import { Controller } from "stimulus"
import $ from 'jquery'
import $$ from "mz_ui/utils/dom"
import { activateLink, activateReload } from "mz_ui/utils/activate_link"

export default class extends Controller {
  doBubbleReload(event) {
    const data = event.detail[0];
    if (data.success) {
      activateReload(event.currentTarget)
    }
    if (this.data.has('stopPropagation')) { event.stopPropagation() }
  }

  doWindowReload(event) {
    const data = event.detail[0];
    if (data.success) {
      activateReload()
    }
    if (this.data.has('stopPropagation')) { event.stopPropagation() }
  }

  doBubbleReloadNoCheck(event) {
    activateReload(event.currentTarget)
    if (this.data.has('stopPropagation')) { event.stopPropagation() }
  }

  doWindowReloadNoCheck(event) {
    activateReload()
    if (this.data.has('stopPropagation')) { event.stopPropagation() }
  }

  doWindowLoad() {
    activateLink(this.data.get('loadUrl'))
  }

  doReplaceContent(event) {
    const content = event.detail[2].response; //event.detail[0];
    const to = this.data.has('replaceTarget') ? $$(this.data.get('replaceTarget')) : this.element;

    setTimeout(function() {
      to.insertAdjacentHTML('afterend', content);
      to.remove();
    }, 13)
  }

  doReloadOrMarkError(event) {
    const data = event.detail[0];

    if (data.success) {
      return activateReload();
    } else {
      if (this.data.has('errorAlert')) { alert(this.data.get('errorAlert')); }
      if (data.errors) {
        const $el = $(this.element);
        $.each(data.errors, function(key, error) {
          $el.find('[name*="[' + key + ']"]').removeBootstrapInvalid();
          $el.find('[name*="[' + key + ']"]').markBootstrapInvalid(error);
        });
      }
    }
  }

  doAlertErrors(event) {
    const data = event.detail[0];
    alert(data.errors);
  }

  doSetupDownload(event) {
    // event.detail[0] -> xhr
    event.detail[0].responseType = 'blob'
  }

  doSaveFile(event) {
    let fileName;
    const blob = event.detail[0];
    const req = event.detail[2];

    // IE/EDGE seems not returning some response header
    if (req.getResponseHeader("content-disposition")) {
      const textdecode = function(encoding, value) {
        if (encoding) {
          const decoder = new TextDecoder(encoding, {fatal: true});
          const bytes = Array.from(value, c => c.charCodeAt(0));
          if (bytes.every(code => code <= 0xFF)) {
            value = decoder.decode(new Uint8Array(bytes));
          }
        }
        return value;
      };

      const contentDisposition = req.getResponseHeader("content-disposition");
      fileName = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/.exec(contentDisposition)[1];
      fileName = textdecode('utf-8', fileName);
    } else {
      const contentType = req.getResponseHeader("content-type");
      fileName = "unnamed." + contentType.substring(contentType.indexOf("/") + 1);
    }

    if (window.navigator.msSaveOrOpenBlob) {
      return window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement`a`;
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  doDownload(event) {
    const {
      url
    } = event.detail[0];
    const a = document.createElement`a`;
    a.style.display = 'none';
    a.href = url;
    a.dataset.turbolinks = false;
    document.body.appendChild(a);
    a.click();
  }
}
