let merge = function(...xs) {
  if ((xs != null ? xs.length : undefined) > 0) {
    return tap({}, m => Array.from(xs).map((x) => (() => {
      const result = [];
      for (let k in x) {
        const v = x[k];
        result.push(m[k] = v);
      }
      return result;
    })()));
  }
};

let tap = function(o, fn) { fn(o); return o; }

let toArray = e => Array.prototype.slice.call(e);

export { merge, tap, toArray }
