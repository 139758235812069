/*! Select2 4.0.3 | https://github.com/select2/select2/blob/master/LICENSE.md */

import jQuery from "jquery"
import "select2"

(function ($) {
  if (jQuery && jQuery.fn && jQuery.fn.select2 && jQuery.fn.select2.amd) var S2 = jQuery.fn.select2.amd;
  return S2.define("select2/i18n/ru", [], function () {
    // Russian
    function ending (count, one, couple, more) {
      if (count % 10 < 5 && count % 10 > 0 &&
        count % 100 < 5 || count % 100 > 20) {
        if (count % 10 > 1) {
          return couple;
        }
      } else {
        return more;
      }

      return one;
    }

    function spinner_template(title) {
      return '<div class="mz-preloader inline-dropdown"><div class="block-inline"><div class="area"><div class="title">'+title+'</div><div class="wrapper"><svg class="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">' +
        '<circle class="path" fill="none" stroke-width="6" cx="33" cy="33" r="30" stroke-linecap="round"/></svg></div></div></div></div>';
    }

    return {
      errorLoading: function () {
        //return 'Невозможно загрузить результаты';
        return $(spinner_template('Поиск…'));
      },
      inputTooLong: function (args) {
        var overChars = args.input.length - args.maximum;

        var message = 'Введите на ' + overChars + ' символ';

        message += ending(overChars, '', 'a', 'ов');

        message += ' меньше';

        return message;
      },
      inputTooShort: function (args) {
        var remainingChars = args.minimum - args.input.length;

        var message = 'Введите ещё хотя бы ' + remainingChars +
          ' символ';

        message += ending(remainingChars, '', 'a', 'ов');

        return message;
      },
      loadingMore: function () {
        return $(spinner_template() + 'Загрузка данных…');
      },
      maximumSelected: function (args) {
        var message = 'Вы можете выбрать не более ' + args.maximum + ' элемент';

        message += ending(args.maximum, '', 'a', 'ов');

        return message;
      },
      noResults: function () {
        return 'Совпадений не найдено';
      },
      searching: function () {
        return $(spinner_template('Поиск…'));
      },
      removeAllItems: function () {
        return 'Удалить все элементы';
      }
    };
  }), {define: S2.define, require: S2.require}
})(jQuery);
