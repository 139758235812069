import { Controller } from "stimulus"
import Turbolinks from "turbolinks"

export default class extends Controller {
  doLoad(event) {
    if ((event != null) && event.detail && event.detail.url) {
      event.stopPropagation();
      return Turbolinks.visit(event.detail.url, {replace: event.detail.turbolinksReplace});
    } else if (this.data.get('url')) {
      return Turbolinks.visit(this.data.get('url'));
    } else {
      return Turbolinks.visit(window.location.href, {replace: true});
    }
  }

  doReload() {
    return Turbolinks.visit(window.location.href, {replace: true});
  }
}
