import { Controller } from "stimulus"
import { markLoading, removeLoading } from "mz_ui/utils/mark_loading"
import $  from "jquery"

let isDirectTarget = el => $(el).is('a,button');
let setupElement = el => $(el).attr('data-disable', '1');
let disableElement = el => markLoading(el, '', 'no-title');
let enableElement = el => removeLoading(el);

export default class extends Controller {
  static targets = ['el']

  connect() {
    if (this.data.get('init')) {
      this.disable();
    }

    if (isDirectTarget(this.element)) {
      setupElement(this.element);
    } else {
      this.elTargets.forEach(el => setupElement(el));
    }
  }

  disconnect() {
    this.enable();
  }

  disable() {
    if (isDirectTarget(this.element)) {
      disableElement(this.element);
    } else {
      this.elTargets.forEach(el => disableElement(el));
    }
  }

  enable() {
    if (isDirectTarget(this.element)) {
      enableElement(this.element);
    } else {
      this.elTargets.forEach(el => enableElement(el));
    }
  }
}
