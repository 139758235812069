let escapeRegExp = string => string.replace(/([.*+?^${}()|[\]\/\\])/g, '\\$1');

let templateSettings = {
  start:        '{{',
  end:          '}}',
  interpolate:  /\{\{(.+?)\}\}/g
};

let template = function(str, data) {
  const c = templateSettings;
  const endMatch = new RegExp("'(?=[^"+c.end.substr(0, 1)+"]*"+escapeRegExp(c.end)+")","g");
  const fn = new Function('obj',
    'var p=[],print=function(){p.push.apply(p,arguments);};' +
      'with(obj||{}){p.push(\'' +
      str.replace(/\r/g, '\\r')
        .replace(/\n/g, '\\n')
        .replace(/\t/g, '\\t')
        .replace(endMatch,"✄")
        .split("'").join("\\'")
        .split("✄").join("'")
        .replace(c.interpolate, "',$1,'")
        .split(c.start).join("');")
        .split(c.end).join("p.push('") +
      "');}return p.join('');"
  );
  if (data) { return fn(data); } else { return fn; }
};

export default template
