import jQuery from "jquery"
import daterangepicker from "daterangepicker"

(function( $ ) {
  var MZDateRangePicker = function(element, options, cb){
    daterangepicker.call(this, element, options, cb);
  };

  MZDateRangePicker.prototype = Object.create(daterangepicker.prototype);
  $.extend(MZDateRangePicker.prototype, {
    updateMonthsInView: function() {
      if (this.endDate) {

        //if both dates are visible already, do nothing
        if (!this.singleDatePicker && this.leftCalendar.month && this.rightCalendar.month &&
          (this.startDate.format('YYYY-MM') == this.leftCalendar.month.format('YYYY-MM') || this.startDate.format('YYYY-MM') == this.rightCalendar.month.format('YYYY-MM'))
          &&
          (this.endDate.format('YYYY-MM') == this.leftCalendar.month.format('YYYY-MM') || this.endDate.format('YYYY-MM') == this.rightCalendar.month.format('YYYY-MM'))
        ) {
          return;
        }

        this.leftCalendar.month = this.startDate.clone().date(2);
        if (!this.linkedCalendars && (this.endDate.month() != this.startDate.month() || this.endDate.year() != this.startDate.year())) {
          this.rightCalendar.month = this.endDate.clone().date(2);
        } else {
          //this.rightCalendar.month = this.startDate.clone().date(2).add(1, 'month');
          // right and left are the same
          this.rightCalendar.month = this.startDate.clone().date(2);
        }

      } else {
        if (this.leftCalendar.month.format('YYYY-MM') != this.startDate.format('YYYY-MM') && this.rightCalendar.month.format('YYYY-MM') != this.startDate.format('YYYY-MM')) {
          this.leftCalendar.month = this.startDate.clone().date(2);
          //this.rightCalendar.month = this.startDate.clone().date(2).add(1, 'month');
          // right and left are the same
          this.rightCalendar.month = this.startDate.clone().date(2);
        }
      }
      if (this.maxDate && this.linkedCalendars && !this.singleDatePicker && this.rightCalendar.month > this.maxDate) {
        this.rightCalendar.month = this.maxDate.clone().date(2);
        //this.leftCalendar.month = this.maxDate.clone().date(2).subtract(1, 'month');
        // right and left are the same
        this.leftCalendar.month = this.maxDate.clone().date(2);
      }
    },

    monthOrYearChanged: function(e) {
      var isLeft = $(e.target).closest('.drp-calendar').hasClass('left'),
        leftOrRight = isLeft ? 'left' : 'right',
        cal = this.container.find('.drp-calendar.'+leftOrRight);

      // Month must be Number for new moment versions
      var month = parseInt(cal.find('.monthselect').val(), 10);
      var year = cal.find('.yearselect').val();

      if (!isLeft) {
        if (year < this.startDate.year() || (year == this.startDate.year() && month < this.startDate.month())) {
          month = this.startDate.month();
          year = this.startDate.year();
        }
      }

      if (this.minDate) {
        if (year < this.minDate.year() || (year == this.minDate.year() && month < this.minDate.month())) {
          month = this.minDate.month();
          year = this.minDate.year();
        }
      }

      if (this.maxDate) {
        if (year > this.maxDate.year() || (year == this.maxDate.year() && month > this.maxDate.month())) {
          month = this.maxDate.month();
          year = this.maxDate.year();
        }
      }

      if (isLeft) {
        this.leftCalendar.month.month(month).year(year);
        if (this.linkedCalendars)
          //this.rightCalendar.month = this.leftCalendar.month.clone().add(1, 'month');
          // right and left are the same
          this.rightCalendar.month = this.leftCalendar.month.clone();
      } else {
        this.rightCalendar.month.month(month).year(year);
        if (this.linkedCalendars)
          //this.leftCalendar.month = this.rightCalendar.month.clone().subtract(1, 'month');
          // right and left are the same
          this.leftCalendar.month = this.rightCalendar.month.clone();
      }
      this.updateCalendars();
    },

    hoverDate: function(e) { },

    clickDate: function(e) {

      if (!$(e.target).hasClass('available')) return;

      var title = $(e.target).attr('data-title');
      var row = title.substr(1, 1);
      var col = title.substr(3, 1);
      var cal = $(e.target).parents('.drp-calendar');
      var date = cal.hasClass('left') ? this.leftCalendar.calendar[row][col] : this.rightCalendar.calendar[row][col];
      var isLeft = cal.hasClass('left');

      //
      // this function needs to do a few things:
      // * alternate between selecting a start and end date for the range,
      // * if the time picker is enabled, apply the hour/minute/second from the select boxes to the clicked date
      // * if autoapply is enabled, and an end date was chosen, apply the selection
      // * if single date picker mode, and time picker isn't enabled, apply the selection immediately
      // * if one of the inputs above the calendars was focused, cancel that manual input
      //

      if (isLeft && (this.endDate || date.isBefore(this.startDate, 'day'))) { //picking start
        if (this.timePicker) {
          var hour = parseInt(this.container.find('.left .hourselect').val(), 10);
          if (!this.timePicker24Hour) {
            var ampm = this.container.find('.left .ampmselect').val();
            if (ampm === 'PM' && hour < 12)
              hour += 12;
            if (ampm === 'AM' && hour === 12)
              hour = 0;
          }
          var minute = parseInt(this.container.find('.left .minuteselect').val(), 10);
          var second = this.timePickerSeconds ? parseInt(this.container.find('.left .secondselect').val(), 10) : 0;
          date = date.clone().hour(hour).minute(minute).second(second);
        }
        //this.endDate = null;
        this.setStartDate(date.clone());
      } else if (!this.endDate && date.isBefore(this.startDate)) {
        //special case: clicking the same date for start/end,
        //but the time of the end date is before the start date
        //this.setEndDate(this.startDate.clone());
      } else if (!isLeft) { // picking end
        if (this.timePicker) {
          var hour = parseInt(this.container.find('.right .hourselect').val(), 10);
          if (!this.timePicker24Hour) {
            var ampm = this.container.find('.right .ampmselect').val();
            if (ampm === 'PM' && hour < 12)
              hour += 12;
            if (ampm === 'AM' && hour === 12)
              hour = 0;
          }
          var minute = parseInt(this.container.find('.right .minuteselect').val(), 10);
          var second = this.timePickerSeconds ? parseInt(this.container.find('.right .secondselect').val(), 10) : 0;
          date = date.clone().hour(hour).minute(minute).second(second);
        }
        this.setEndDate(date.clone());
        if (this.autoApply) {
          this.calculateChosenLabel();
          this.clickApply();
        }
      }

      if (this.singleDatePicker) {
        this.setEndDate(this.startDate);
        if (!this.timePicker)
          this.clickApply();
      }

      this.updateView();

      //This is to cancel the blur event handler if the mouse was in one of the inputs
      e.stopPropagation();

    },

    setStartDate: function(startDate) {
      daterangepicker.prototype.setStartDate.call(this, startDate);
      this.container.find('.drp-selected').html(this.startDate.format(this.locale.format) + this.locale.separator + this.endDate.format(this.locale.format));
    },

    elementChangedStart: function(dateString) {
      if (!dateString.length) return;
      var start = moment(dateString, this.locale.format);
      if (!start.isValid()) return;

      this.setStartDate(start);
      this.updateView();
    },

    elementChangedEnd: function(dateString) {
      if (!dateString.length) return;
      var end = moment(dateString, this.locale.format);
      if (!end.isValid()) return;

      this.setEndDate(end);
      this.updateView();
    }
  });

  $.fn.mzDaterangePicker = function(options, callback) {
    var implementOptions = $.extend(true, {}, $.fn.daterangepicker.defaultOptions, options);
    this.each(function() {
      var el = $(this);
      if (el.data('daterangepicker'))
        el.data('daterangepicker').remove();
      el.data('daterangepicker', new MZDateRangePicker(el, implementOptions, callback));

      $(el.data('daterangepicker').container).addClass('mz');
    });
    return this;
  };
})(jQuery);
