import { Controller } from "stimulus"
import { fire } from "@rails/ujs"
import $$ from "mz_ui/utils/dom"

export default class extends Controller {
  doLoad() {
    const to = this.data.has('to') ? this.data.get('to') : this.element.getAttribute('href');
    fire($$(to), 'load');
  }

  doLoadMobile(event) {
    if (this.element === event.target) {
      fire(this.element, 'load');
    }
  }
}
