import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = ['table']

  connect() {
    this.cellsMatrix = this._scanTable();

    this.arrow = {
      enter: 13,
      left: 37,
      up: 38,
      right: 39,
      down: 40
    };

    $(this.tableTarget).on('keydown.table-keynav', 'input,textarea,select', this._handleKey.bind(this));
  }

  disconnect() {
    $(this.tableTarget).off('.table-keynav');
  }

  _handleKey(e) {
    // shortcut for key other than arrow keys
    if ($.inArray(e.which, [this.arrow.left, this.arrow.up, this.arrow.right, this.arrow.down, this.arrow.enter]) < 0) {
      return;
    }

    var input = e.target;
    var td = $(e.target).closest('td,th');
    var moveTo = null;

    switch (e.which) {
      case this.arrow.left: {
        if (input.selectionStart === 0) {
          moveTo = td.prevAll('td:has(input,textarea,select):first,th:has(input,textarea,select):first');
        }
        break;
      }

      case this.arrow.right: {
        if (input.selectionEnd === input.value.length) {
          moveTo = td.nextAll('td:has(input,textarea,select):first,th:has(input,textarea,select):first');

          if (moveTo.length === 0) { // last col on 'right' arrow - move to next row first cell
            var tr = td.closest('tr');
            var nextTr = tr.nextAll('tr:has(input,textarea,select):first');
            moveTo = nextTr.find('td:has(input,textarea,select):first,th:has(input,textarea,select):first');
          }
        }
        break;
      }

      case this.arrow.up:
      case this.arrow.down:
      case this.arrow.enter: {
        var pos = td.data('cellPos');
        var moveToCell = null;

        if (e.which === this.arrow.down || e.which === this.arrow.enter) {
          var offset = 1;

          moveToCell = this._getCellAt(pos.top + offset, pos.left);

          while (moveToCell && (moveToCell.is(td) || !moveToCell.is(':has(input,textarea,select)'))) {
            offset++;
            moveToCell = this._getCellAt(pos.top + offset, pos.left);
          }
        } else if (e.which === this.arrow.up) {
          var offset = 1;

          moveToCell = this._getCellAt(pos.top - offset, pos.left);

          while (moveToCell && (moveToCell.is(td) || !moveToCell.is(':has(input,textarea,select)'))) {
            offset++;
            moveToCell = this._getCellAt(pos.top - offset, pos.left);
          }
        }

        if (moveToCell) {
          moveTo = moveToCell;
        }

        break;
      }
    }

    if (moveTo && moveTo.length) {
      e.preventDefault();

      moveTo.find('input,textarea,select').each(function (i, input) {
        input.focus();
        input.select();
      });
    }
  }

  _getCellAt(y, x) {
    var cell = null;

    if (this.cellsMatrix[y] && this.cellsMatrix[y][x]) {
      return this.cellsMatrix[y][x];
    }

    return cell;
  }

  _scanTable() {
    let $table = $(this.tableTarget);
    var m = [];
    $table.find("tr, thead > tr, tbody > tr, tfoot > tr").each(function (y, row) {
      $(row).children("td, th").each(function (x, cell) {
        var $cell = $(cell),
          cspan = $cell.attr("colspan") | 0,
          rspan = $cell.attr("rowspan") | 0,
          tx, ty;
        cspan = cspan ? cspan : 1;
        rspan = rspan ? rspan : 1;
        for (; m[y] && m[y][x]; ++x) ;  //skip already occupied cells in current row
        for (tx = x; tx < x + cspan; ++tx) {  //mark matrix elements occupied by current cell with true
          for (ty = y; ty < y + rspan; ++ty) {
            if (!m[ty]) {  //fill missing rows
              m[ty] = [];
            }
            m[ty][tx] = $cell;
          }
        }
        var pos = {top: y, left: x};
        $cell.data("cellPos", pos);
      });
    });

    return m;
  }
}
