// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'

import { componentsContext as mzUiCmpContext, controllersContext as mzUiCtrlContext } from '../mz_ui_glue.js.erb'

const application = Application.start()

const context = require.context("controllers", true, /_controller\.js$/)
const componentsContext = require.context("../../components", true, /_controller\.js$/)

application.load(definitionsFromContext(mzUiCmpContext))
application.load(definitionsFromContext(mzUiCtrlContext))
application.load(definitionsFromContext(componentsContext))
application.load(definitionsFromContext(context))

const context2 = require.context("bootstrap", true, /\.js$/)

StimulusReflex.initialize(application, { consumer })
