import { Controller } from "stimulus"
import $ from "jquery"
import 'bootstrap/js/dist/tooltip'

export default class extends Controller {
  connect() {
    $(this.element).tooltip()
  }

  disconnect() {
    $(this.element).tooltip('dispose')
  }
}
