import { Controller } from "stimulus"
import $$ from "mz_ui/utils/dom"
import { fire as railsFire } from "@rails/ujs"

export default class extends Controller {
  fire(event) {
    const to = $$(this.data.get('to'));
    const eventName = this.data.has('event') ? this.data.get('event') : 'activate';
    railsFire(to, eventName);
    if (event != null) { return event.preventDefault(); }
  }
}
