import { Controller } from "stimulus"
import { markLoading } from "mz_ui/utils/mark_loading"
import { activateLink } from "mz_ui/utils/activate_link"

export default class extends Controller {
  onLogon(event) {
    const data = event.detail[0]

    if (data.success) {
      markLoading('.center-block')
      activateLink(data.url)
    } else if (data.errors.base) {
      alert(data.errors.base)
    }
  }

  onLogout(event) {
    const data = event.detail[0]
    if (data.success) {
      activateLink(data.url)
    }
  }
}
