import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ 'wrapper', 'rootLink' ]

  hideSubmenu() {
    return $('.dropdown-submenu .show').removeClass('show');
  }

  toggleSubmenu(event) {
    const $link = $(event.currentTarget);

    if (!$link.next().hasClass('show')) {
      $link.parents('.dropdown-menu').first().find('.show').removeClass('show');
    }

    $link.next('.dropdown-menu').toggleClass('show');

    if ($('body').width() < 1200) {
      $link.nextAll('.dropdown-submenu').addClass('show');
      $link.parents('.dropdown-submenu').siblings('li').hide();
      $('.nav-link:not(.back)').hide();
      $('.dropdown-submenu').toggleClass('show');
      this.showBack();
    }

    event.preventDefault();
    return event.stopPropagation();
  }

  hideNav(event) {
    if ($('body').width() < 1200) {
      const $link = $(event.currentTarget);
      if (!$link.next().hasClass('show')) {
        return $link.parents('.nav-item').siblings('li:not(.back)').hide();
      }
    }
  }

  toggle() {
    $(this.wrapperTarget).addClass('open');
    $('body').css('overflow', 'hidden');
    return this.hideBack();
  }

  back(event) {
    $(this.rootLinkTargets).show();
    this.hideBack();
    $('.nav-link:not(.back)').show();
    $('.dropdown-submenu')
      .removeClass('show')
      .siblings('li').show();
    if (event != null) { return event.preventDefault(); }
  }

  close(event) {
    this.back();
    $(this.wrapperTarget).removeClass('open');
    $('body').css('overflow', 'auto');
    if (event != null) { return event.preventDefault(); }
  }

  showBack() {
    return $('.nav-item.back > a:first').removeClass('invisible');
  }

  hideBack() {
    return $('.nav-item.back > a:first').addClass('invisible');
  }
}
