import { Controller } from "stimulus"
import $ from 'jquery'
import { markLoading, removeLoading } from "mz_ui/utils/mark_loading"

export default class extends Controller {
  connect() {
    if (!this.data.get('skipInit')) {// && Rails.matches @element, '.tab-pane.show'
      this.doLoad();
    }
    return this.startPolling();
  }

  disconnect() {
    return this.stopPolling();
  }

  doLoad(event) {
    let url;
    if ((event != null) && event.detail && event.detail.url) {
      ({
        url
      } = event.detail);
      this.data.set('url', url);
    } else if (this.data.has('url')) {
      url = this.data.get('url');
    }

    if (url != null) {
      const el = this.element;
      const ml =  this.data.get('markLoading');

      if (ml) { markLoading(el); }

      $(el).load(url, function() {
        if (ml) { return removeLoading(el); }
      });
    }

    if (event != null) { return event.stopPropagation(); }
  }

  loadContent() {
    const url = this.data.get('url');
    return $(this.element).load(url);
  }

  startPolling() {
    if (this.data.has('pollInterval')) {
      return this.pollTimer = setInterval((() => {
        return this.doLoad();
      }
      ), this.data.get('pollInterval'));
    }
  }

  stopPolling() {
    if (this.pollTimer) { return clearInterval(this.pollTimer); }
  }
}
